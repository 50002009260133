<template lang="pug">
  .Calendar.p-tx-lg
    .Calendar.max-w-sm.mx-auto.bg-white.rounded-xl.shadow-bx-small-sm
      .p-tx-m.flex.justify-between
        button(@click="lastMonth") <
        h4.text-sm.m-0.font-bold.mx-3 {{ month }} {{ year }}
        button(@click="nextMonth") >
      ul.grid.grid-cols-7.place-items-center.px-tx-lg.text-primary.font-semibold.gap-3
        li(v-for="(day, indexDay) in days" :key="indexDay")
          small.text-xs {{ day }}
      ul.grid.grid-cols-7.place-items-center.p-tx-lg.pt-0.gap-3.DateData.text-dark.transition
        div(v-for="(af, indexMonthAfter) in afterMonths" :key="indexMonthAfter + '__beforeMonth'").transition
          li.relative(@click="() => af.grayDay ? monthEvents(af) : null")
            small(:class="af.grayDay ? ' font-bold cursor-pointer ' : ' opacity-60 '").relative.z-1.text-dark
              | {{ getTotalDays(currentMonth - 1) - (af.dayRenderer - 1) }}
            div(v-if="selectedDay && (af.day === selectedDay.day && af.grayDay && af.month === selectedDay.month && af.yearNumber === selectedDay.yearNumber)" class="hover:scale-105").transform.z-20.duration-200.ease-in-out.flex.items-center.justify-center.bg-good_color.rounded-bd-complete.px-tx-sm.w-8.h-8.absolute.manageToday.transition
              small.text-good_background {{ af.day }}
            div(v-if="af.from && !(af.from && af.to) && !af.neverGetTo").z-10.flex.items-center.justify-center.px-tx-sm.w-8.h-8.absolute.manageToFrom
              small {{ af.day }}
            div(v-if="af.to && !(af.from && af.to)").flex.items-center.justify-center.px-tx-sm.w-14.h-8.absolute.manageTo
              small {{ af.day }}
            div(v-if="af.neverGetTo").flex.items-center.justify-center.rounded-bd-complete.px-tx-sm.w-8.h-8.absolute.manageOnly
              small {{ af.day }}
            div(v-if="af.fromActivated && !af.from && !af.to").flex.items-center.justify-center.px-tx-sm.w-14.h-8.absolute.manageActivetor
              small {{ af.day }}
        div(v-for="(month, indexMonth) in monthsMiddle" :key="indexMonth + '__month'").transition
          //- v-show="month.dayWeekTEXT !== 'LUN' && month.day > currentDay - 1"
          li.relative(
            @click="() => currentMonth === currentDate.getMonth() && currentYear === currentDate.getFullYear() ? (!month.grayDay ? (month.day >= currentDay || month.month !== monthNames[currentMonth] ? monthEvents(month) : null) : null) : (month.grayDay ? null : monthEvents(month))"
            :class="currentMonth === currentDate.getMonth() && currentYear === currentDate.getFullYear() ? ((month.grayDay ? ' opacity-50 ' : ' font-bold ') + (month.day < currentDay ? (month.month !== monthNames[currentMonth] ? ' cursor-pointer ' : ' opacity-50 ') : ' cursor-pointer ')) : (month.grayDay ? ' opacity-50 ' : ' font-bold cursor-pointer ') "
          )
            //- small(:class="month.day < currentDay && 'opacity-50 dk-visibility'").text-dark.relative.z-2 {{ month.day }}
            small(:class="month.month !== monthNames[currentMonth] ? ' ' : ' text-dark '").relative.z-2 {{ month.day }}
            div(v-if="month.day === currentDay && currentMonth === currentDate.getMonth() && currentYear === currentDate.getFullYear()" class="hover:scale-105").transform.z-20.duration-200.ease-in-out.flex.items-center.justify-center.bg-primary_other.rounded-bd-complete.px-tx-sm.w-8.h-8.absolute.manageToday.transition
              small.text-info_background {{ month.day }}
            div(v-if="selectedDay && (month.day === selectedDay.day && !month.grayDay && month.month === selectedDay.month && month.yearNumber === selectedDay.yearNumber)" class="hover:scale-105").transform.z-20.duration-200.ease-in-out.flex.items-center.justify-center.bg-good_color.rounded-bd-complete.px-tx-sm.w-8.h-8.absolute.manageToday.transition
              small.text-good_background {{ month.day }}
            div(v-if="month.from && !(month.from && month.to) && !month.neverGetTo").z-10.flex.items-center.justify-center.px-tx-sm.w-8.h-8.absolute.manageToFrom
              small {{ month.day }}
            div(v-if="month.to && !(month.from && month.to)").flex.items-center.justify-center.px-tx-sm.w-14.h-8.absolute.manageTo
              small {{ month.day }}
            div(v-if="month.neverGetTo").flex.items-center.justify-center.rounded-bd-complete.px-tx-sm.w-8.h-8.absolute.manageOnly
              small {{ month.day }}
            div(v-if="month.fromActivated && !month.from && !month.to").flex.items-center.justify-center.px-tx-sm.w-14.h-8.absolute.manageActivetor
              small {{ month.day }}
        div(v-for="(bm, indexMonthBefore) in beforeMonths" :key="indexMonthBefore + '__afterMonth'")
          li.relative(
            @click="() => !bm.grayDay ? monthEvents(bm) : null"
          )
            small(:class="bm.grayDay ? ' opacity-50 ' : ' font-bold cursor-pointer '").relative.z-10.text-dark
              | {{ bm.day }}
            div(v-if="selectedDay && (bm.day === selectedDay.day && !bm.grayDay && bm.month === selectedDay.month && bm.yearNumber === selectedDay.yearNumber)" class="hover:scale-105").transform.z-20.duration-200.ease-in-out.flex.items-center.justify-center.bg-good_color.rounded-bd-complete.px-tx-sm.w-8.h-8.absolute.manageToday.transition
              small.text-good_background {{ bm.day }}
</template>

<script>
const currentDate = new Date();
var moment = require("moment");
moment.locale("es");
import { mapState, } from "vuex";

export default {
  name: "Calendar",
  props: {
    dates: Array,
    onClickEvent: Function,
  },
  data() {
    return {
      month: "",
      year: "",
      selectedDay: null,
      days: ["LUN", "MAR", "MIE", "JUE", "VIE", "SAB", "DOM"],
      daysEn: ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"],
      monthNames: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      currentDay: currentDate.getDate(),
      currentDayWeek: 0,
      currentMonth: currentDate.getMonth(),
      currentYear: currentDate.getFullYear(),
      currentDate,
      afterMonths: [],
      beforeMonths: [],
      monthsMiddle: [],
      latestDay: 1,
      afterTheMonthArray: [],
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    ...mapState({
      schedule: (state) => state.appointments.schedule,
    }),
  },
  methods: {
    getData() {
      this.defineProperties();
      this.isLeap();
      this.getDayStarted();
      this.writeMonth();
    },
    writeMonth(month) {
      let mondayStarted = 0;
      // const monthNumber = this.currentMonth !== 0 ? this.currentMonth - 1 : 11;

      for (let index = 0; index <= this.getTotalDays(this.currentMonth); index++) {
        if (index === 0) continue;
        const dayWeekTEXT = this.days[new Date(this.currentYear, this.currentMonth, index).getDay() - 1] || "DOM";

        if (dayWeekTEXT === "LUN" && index <= this.currentDay) {
          mondayStarted = index;
        }
      }

      this.afterMonths = [];
      for (let index = this.getDayStarted(); index > 0; index--) {
        const localCurrentMonth = this.currentMonth === 0 ? 1 : this.currentMonth;
        let availables = this.getDaysArray(
          moment().weekday(0),
          moment().add(this.schedule.maximumAdvance - 1, "days") //FIXME: fIX THIS this.schedule.minimumNotice + 1
        );
        const filteredAvailables = availables.filter((day) => {
          return (+moment(day.dt).format("M") === localCurrentMonth) && (+moment(day.dt).format("Y") === this.currentYear);
        });
        const mapedFiltered = filteredAvailables.map((day) => +moment(day.dt).format("DD"));

        // if (mondayStarted !== 0) continue;
        const monthNumber = this.currentMonth !== 0 ? this.currentMonth - 1 : 11;
        const yearNumber = this.currentMonth !== 0 ? this.currentYear : this.currentYear - 1;
        const dayWeekTEXT = this.days[new Date(this.currentYear, this.currentMonth, index).getDate() - 1] || "DOM";
        const month = this.monthNames[monthNumber];

        let findMaped = mapedFiltered.filter((mf) => {
          return mf === (this.getTotalDays(this.currentMonth - 1) - (index - 1));
        });

        // const filteredFinded = mapedFiltered.find((mf) => {
        //   return mf === this.getTotalDays(localCurrentMonth - 1);
        // });

        // let findDay = arrayExclusion.find((day) => {
        //   return (+day.day === index + 1) && (+moment(day).format("M") === this.currentMonth + 1) && (+moment(day).format("Y") === this.currentYear);
        // });

        // const dayWeekTEXTEn = this.daysEn[moment(day).weekday()] || "SUNDAY";
        // let findWeek = this.schedule.availableTimeRanges.find((atr) => {
        //   return atr.day === dayWeekTEXTEn;
        // });

        this.afterMonths.push({
          monthNumber,
          yearNumber,
          dayWeekTEXT,
          grayDay: findMaped.length,
          dayWeek: this.getWeekOfMonth(index),
          month,
          dayRenderer: index,
          day: this.getTotalDays(this.currentMonth - 1) - (index - 1),
        });
      }

      let minimumDay = this.currentDay + this.schedule.minimumNotice;
      let arrayExclusion = [];
      
      let availables = this.getDaysArray(
        moment().weekday(0),
        moment().add(this.schedule.maximumAdvance  - 1, "days") //FIXME: fIX THIS this.schedule.minimumNotice + 1
      );

      if (this.schedule.exclusionDates && this.schedule.exclusionDates.length) {
        arrayExclusion = this.schedule.exclusionDates.map((schedule) => {
          return {
            ...schedule,
            day: moment(schedule.date, 'YYYY/MM/DD').format("D"),
          };
        });
      }

      if (!month || (this.currentMonth === currentDate.getMonth() && this.currentYear === currentDate.getFullYear())) {
        for (let index = 0; index < this.getTotalDays(this.currentMonth); index++) {
          let day = availables[index];
          if (day && day.dt === 0) continue;
          if (index === availables.length) continue;
          if (day) {
            this.latestDay = parseInt(moment(day.dt).format("DD"));
          } else {
            this.latestDay += 1;
          }
          if (this.latestDay > this.getTotalDays(this.currentMonth)) continue;
          if (day && (this.currentMonth !== (parseInt(moment(day.dt).format("MM"))-1))) this.afterTheMonthArray.push(parseInt(moment(day.dt).format("DD")));

          if (day && (parseInt(moment(day.dt).format("DD")) < mondayStarted)) {
            if (moment(day.dt).format("M") === currentDate.getMonth()) {
              continue;
            }
          }
          let findDay = arrayExclusion.find((day) => {
            return (+day.day === this.latestDay) && (+moment(day.date).format("M") === +moment(day.date).format("M")) && (+moment(day.date).format("Y") === this.currentYear);
          });

          const dayWeekTEXT = day ? this.days[moment(day.dt).weekday()] : "DOM";
          const dayWeekTEXTEn = day ? this.daysEn[moment(day.dt).weekday()] : "SUNDAY";
          const yearNumber = this.currentYear;
          let findWeek = this.schedule.availableTimeRanges.find((atr) => atr.day === dayWeekTEXTEn);

          this.monthsMiddle.push({
            day: day ? parseInt(moment(day.dt).format("DD")) : this.latestDay,
            dayWeekTEXT,
            grayDay: !day || ((this.latestDay < minimumDay ) && (day ? parseInt(moment(day.dt).format("M")) < this.currentMonth : false)) || findDay || !findWeek || !day.disponible,
            month: day ? this.monthNames[parseInt(moment(day?.dt).format("MM"))-1] : this.monthNames[this.latestDay],
            dayWeek: day ? this.getWeekOfMonth(parseInt(moment(day?.dt).format("DD"))) : this.getWeekOfMonth(this.latestDay),
            yearNumber,
          });
        }
      } else {
        for (let index = 0; index < this.getTotalDays(this.currentMonth) + 1; index++) {
          let day = availables[index + 1];
          if (day && day.dt === 0) continue;
          if (index === availables.length) continue;
          const filteredAvailables = availables.filter((day) => {
            return (+moment(day.dt).format("M") === this.currentMonth + 1) && (+moment(day.dt).format("Y") === this.currentYear);
          });
          const mapedFiltered = filteredAvailables.map((day) => +moment(day.dt).format("DD"));
          if (index === 0) continue;
          // const ammount = this.currentMonth !== 11 ? 0 : 1;
          const dayWeekTEXT = this.days[moment(day).weekday()] || "DOM";

          let findDay = arrayExclusion.find((day) => {
            return (+day.day === index) && (+moment(day.date).format("M") === this.currentMonth + 1) && (+moment(day.date).format("Y") === this.currentYear);
          });

          const dayWeekTEXTEn = this.daysEn[moment(day).weekday()] || "SUNDAY";
          const yearNumber = this.currentYear;
          let findWeek = this.schedule.availableTimeRanges.find((atr) => {
            return atr.day === dayWeekTEXTEn;
          });

          let findMaped = mapedFiltered.find((mf) => {
            return mf === index;
          });

          let monthNumber = 0;

          if (this.currentMonth !== 11) {
            monthNumber = this.currentMonth;
          } else {
            monthNumber = 11;
          }

          this.monthsMiddle.push({
            day: index,
            dayWeekTEXT,
            grayDay: !findMaped || findDay || !findWeek || (!day.disponible && +moment(day.dt).format("M") === this.currentMonth + 1) && (+moment(day.dt).format("Y") === this.currentYear),
            month: this.monthNames[monthNumber],
            dayWeek: this.getWeekOfMonth(index),
            yearNumber,
          });
        }
      }

      for (let index = 1; index <= this.getTotalDays(this.currentMonth + 1); index++) {
        if (!month || (this.currentMonth === currentDate.getMonth() && this.currentYear === currentDate.getFullYear())) {
          const counterMinimum = this.afterTheMonthArray.length;
          if (index <= counterMinimum) continue;
        }
        let monthNumber, yearNumber;
        const dayWeekTEXT = this.days[this.getWeekOfMonth(index)] || "DOM";

        if (this.currentMonth !== 11) {
          monthNumber = this.currentMonth + 1;
          yearNumber = this.currentYear;
        } else {
          monthNumber = 0;
          yearNumber = this.currentYear + 1;
        }

        let availables = this.getDaysArray(
          moment().weekday(0),
          moment().add(this.schedule.maximumAdvance - 1, "days") //FIXME: fIX THIS this.schedule.minimumNotice + 1
        );
        const filteredAvailables = availables.filter((day) => {
          return (+moment(day.dt).format("M") === (monthNumber === 0 ? 1 : monthNumber + 1)) && (+moment(day.dt).format("Y") === yearNumber);
        });
        const mapedFiltered = filteredAvailables.map((day) => +moment(day.dt).format("DD"));

        let findDay = arrayExclusion.find((day) => {
          return (+day.day === index) && (+moment(day.date).format("M") === this.currentMonth + 2) && (+moment(day.date).format("Y") === this.currentYear);
        });

        this.beforeMonths.push({
          monthNumber,
          dayWeekTEXT,
          yearNumber,
          grayDay: !mapedFiltered.length || findDay,
          month: this.monthNames[monthNumber],
          day: index,
          dayWeek: this.getWeekOfMonth(index),
        });
      }

      if (this.dates?.length) {
        this.monthsMiddle = this.getDates(true);
        this.afterMonths = this.getDates(false);
      }
    },
    getWeekOfMonth(firstWeekday) {
      let offsetDate = currentDate.getDate() + (firstWeekday - 1);
      return Math.floor(offsetDate / 7);
    },
    getTotalDays(month) {
      if (month === -1) month = 11;

      if (month === 0 || month === 2 || month === 4 || month === 6 || month === 7 || month === 9 || month === 11) {
        return 31;
      } else if (month === 3 || month === 5 || month === 8 || month === 10) {
        return 30;
      } else {
        return this.isLeap() ? 29 : 28;
      }
    },
    isLeap() {
      return (
        (this.currentYear % 100 != 0) &&
        (this.currentYear % 4 === 0) &&
        (this.currentYear % 400 != 0)
      );
    },
    getDayStarted(year, month) {
      let start = new Date(year || this.currentYear, month || this.currentMonth, 1);
      return ((start.getDay() - 1) === -1) ? 6 : start.getDay() - 1;
    },
    getMonday(d) {
      d = new Date(d);
      let day = d.getDay(),
          diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    lastMonth() {
      this.afterTheMonthArray = [];
      this.beforeMonths = [];
      this.monthsMiddle = [];
      if (this.currentMonth !== 0) {
        this.currentMonth--;
      } else {
        this.currentMonth = 11;
        this.currentYear--;
      }

      this.defineProperties();
      this.isLeap();
      this.getDayStarted();
      this.writeMonth(true);
    },
    nextMonth() {
      this.afterTheMonthArray = [];
      this.beforeMonths = [];
      this.monthsMiddle = [];
      if (this.currentMonth !== 11) {
        this.currentMonth++;
      } else {
        this.currentMonth = 0;
        this.currentYear++;
      }

      this.defineProperties();
      this.isLeap();
      this.getDayStarted();
      this.writeMonth(true);
    },
    setNewDate() {
      this.afterMonths = [];
      this.beforeMonths = [];
      this.monthsMiddle = [];

      currentDate.setFullYear(this.currentYear, this.currentMonth, this.currentDay);
      this.getData();
    },
    defineProperties() {
      this.currentDayWeek = this.getWeekOfMonth(this.currentDay);
      this.month = this.monthNames[this.currentMonth].toUpperCase();
      this.year = `${this.currentYear}`;
    },
    getDates(middle) {
      if (this.dates?.length) {
        let fromActivated = false;
        let dateObject = null;

        const datesInMonth = this.dates.filter((date) => {
          return (
            date.from.getMonth() === this.currentMonth &&
            date.from.getFullYear() === this.currentYear
          );
        });

        const datesInYear = this.dates.filter((date) => {
          return (
            date.from.getMonth() === this.currentMonth - 1 &&
            date.from.getFullYear() === this.currentYear
          );
        });

        const monthDecide = middle ? this.monthsMiddle : this.afterMonths;

        return monthDecide.map((month) => {
          if (middle) {
            for (const date of datesInMonth) {
              const from = month.day === date.from.getDate();
              const to = month.day === date.to?.getDate();
              if (from || to) {
                if (from && !date.neverGetTo) {
                  fromActivated = true;
                  dateObject = date;
                }
                if (to) {
                  fromActivated = false;
                  dateObject = date;
                }
  
                return ({
                  ...month,
                  from: from && date.from.getDate(),
                  to: to && date.from.getDate(),
                  fromActivated,
                  neverGetTo: date.neverGetTo,
                  dateObject,
                });
              }
            }
          } else {
            for (const date of datesInYear) {
              const day = this.getTotalDays(this.currentMonth - 1) - (month.day - 1);
              const from = day === date.from.getDate();
              const to = day === date.to?.getDate();
              if (from || to) {
                if (from && !date.neverGetTo) {
                  fromActivated = true;
                  dateObject = date;
                }
                if (to) {
                  fromActivated = false;
                  dateObject = date;
                }
  
                return ({
                  ...month,
                  day: day,
                  from: from && date.from.getDate(),
                  to: to && date.from.getDate(),
                  fromActivated,
                  neverGetTo: date.neverGetTo,
                  dateObject,
                });
              }
            }
          }
          return {
            ...month,
            fromActivated,
          };
        });
      }
    },
    monthEvents (month) {
      this.selectedDay = month;
      if (this.onClickEvent) {
        this.onClickEvent(month);
      }
    },
    
    getDaysArray(start, end) {
      for(var arr = [],dt = new Date(start); dt <= end; dt.setDate(dt.getDate()+1)){
        if ((dt < moment().add(this.schedule.minimumNotice, "days"))) {
          arr.push({
            dt: moment(dt).format("YYYY-MM-DD"),
            disponible: false,
          });
          continue;
        }
        arr.push({
          dt: moment(dt).format("YYYY-MM-DD"),
          disponible: true,
        });
      }
      return arr;
    },
  },
};
</script>

<style scoped>
  .manageToday {
    left: -9px;
    top: -4px;
  }
  .manageToday small {
    margin-right: 0px;
  }
  .DateData {
    max-height: 155px;
    overflow: hidden;
    padding-top: 8px;
  }
  .toTop {
    opacity: 0;
  }
  .manageToFrom, .manageTo, .manageOnly {
    left: -8px;
    top: -4px;
    background-color: #edecf1;
    color: gray;
    cursor: pointer;
  }
  .manageToFrom {
    border-radius: 50px 0 0 50px;
    left: -5px;
  }
  .manageTo {
    border-radius: 0 50px 50px 0;
    left: -26px;
  }
  .manageActivetor {
    left: 2%;
    top: 50%;
    border-radius: 5px;
    background-color: #edecf1;
    color: gray;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  .manageOnly {
    left: -14px;
  }
</style>
